import React, { useEffect, useState } from 'react'

import { Empty, Layout } from 'components';
import { AppConstants } from 'constant';
import hotspotTypes from '../../../../assets/hotspots.js';
import EngageHotspotModal from './hotspot.js';
import { AppUtils } from 'utils/index.js';
import { useTranslation } from 'react-i18next';

const { HEADER_TYPES } = AppConstants;

const src = '/vista/volunteer/index.htm?skip-loading&media=VirtualSpace';

const VolunteerVistaViewScreen = () => {

  const { t } = useTranslation();
  const [finished, setFinished] = useState(false);
  const [showModal, setShowModal] = useState(undefined);

  useEffect(() => {
    if (!finished) {
      return;
    }
    window.frames['vista'].triggerAction = (e, type) => setShowModal({ ...hotspotTypes.Volunteer[type], type: type });
  }, [finished]);

  if (!AppUtils.isUserLogged()) {
    return (
      <Layout type={HEADER_TYPES.VOLUNTEER}>
        <div>
          <div className='text-center'><Empty messageKey={t('functions.volunteer.message.login')} /></div>
        </div>
      </Layout>
    )
  }

  return (
    <Layout type={HEADER_TYPES.VOLUNTEER}>
      <div>
        <EngageHotspotModal el={showModal} onDismiss={() => setShowModal(undefined)} show={showModal} />
        <iframe
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          src={src}
          id='vista'
          name="vista"
          title="vista"
          width="100%"
          height="100%"
          allowFullScreen={false}
          onLoad={() => setFinished(true)}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
          sandbox="allow-scripts allow-same-origin">
        </iframe>
      </div>
    </Layout>
  )
}

export default VolunteerVistaViewScreen;